// A page that relies on publicRuntimeConfig must use getInitialProps to opt-out of Automatic Static Optimization.
// Runtime configuration won't be available to any page (or component in a page) without getInitialProps.
const env = () => {
  return {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    NEXT_PUBLIC_API_URI: process.env.NEXT_PUBLIC_API_URI,
    NEXT_PUBLIC_STATUS_URI: process.env.NEXT_PUBLIC_STATUS_URI,
    NEXT_PUBLIC_DEFAULT_LOCALE: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
    NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX,
    NEXT_PUBLIC_CLIENT_ID: process.env.NEXT_PUBLIC_CLIENT_ID,
    NEXT_PUBLIC_CMS_URI: process.env.NEXT_PUBLIC_CMS_URI,
    REVALIDATE_SECONDS:
      process.env.REVALIDATE_SECONDS && !isNaN(Number(process.env.REVALIDATE_SECONDS))
        ? Number(process.env.REVALIDATE_SECONDS)
        : 5,
    NEXT_PUBLIC_MEDIA_URI: process.env.NEXT_PUBLIC_MEDIA_URI,
    NEXT_PUBLIC_HERE_API_KEY: process.env.NEXT_PUBLIC_HERE_API_KEY,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    CONFIG_BUILD_ID: process.env.CONFIG_BUILD_ID,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_SENTRY_PROJECT: process.env.NEXT_PUBLIC_SENTRY_PROJECT,
    NEXT_PUBLIC_HSCODE_APP_ID: process.env.NEXT_PUBLIC_HSCODE_APP_ID,
    NEXT_PUBLIC_HSCODE_SEARCH_API_KEY: process.env.NEXT_PUBLIC_HSCODE_SEARCH_API_KEY,
    NEXT_PUBLIC_HSCODE_INDEX: process.env.NEXT_PUBLIC_HSCODE_INDEX,
    COOKIE_SECRET: process.env.COOKIE_SECRET,
    ALLOWED_ORIGINS: process.env.ALLOWED_ORIGINS,
    AUTH_CLIENT_ID: process.env.AUTH_CLIENT_ID,
    AUTH_CLIENT_SECRET: process.env.AUTH_CLIENT_SECRET,
    AUTH_AUTHORIZATION_ENDPOINT: process.env.AUTH_AUTHORIZATION_ENDPOINT,
    AUTH_TOKEN_ENDPOINT: process.env.AUTH_TOKEN_ENDPOINT,
    AUTH_REDIRECT_URI: process.env.AUTH_REDIRECT_URI,
    NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN: process.env.NEXT_PUBLIC_AUTH_PROFILE_URL_ORIGIN,
    NEXT_PUBLIC_AUTH_LOGOUT_ENDPOINT: process.env.NEXT_PUBLIC_AUTH_LOGOUT_ENDPOINT,
    NEXT_PUBLIC_AUTH_ORIGIN: process.env.NEXT_PUBLIC_AUTH_ORIGIN,
    NEXT_PUBLIC_AUTH_PASSWORD_EMAIL_ENDPOINT: process.env.NEXT_PUBLIC_AUTH_PASSWORD_EMAIL_ENDPOINT,
    NEXT_PUBLIC_AUTH_PASSWORD_CHANGE_ENDPOINT: process.env.NEXT_PUBLIC_AUTH_PASSWORD_CHANGE_ENDPOINT,
    NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    NEXT_PUBLIC_OEC_VISUALIZER_DATASET: process.env.NEXT_PUBLIC_OEC_VISUALIZER_DATASET,
    NEXT_PUBLIC_OEC_VISUALIZER_YEAR: process.env.NEXT_PUBLIC_OEC_VISUALIZER_YEAR,
    NEXT_PUBLIC_APP_URL_ORIGIN: process.env.NEXT_PUBLIC_APP_URL_ORIGIN,
    NEXT_PUBLIC_MAP_DEFAULT_LOCATION: process.env.NEXT_PUBLIC_MAP_DEFAULT_LOCATION,
    NEXT_PUBLIC_MAP_DEFAULT_ZOOM:
      process.env.NEXT_PUBLIC_MAP_DEFAULT_ZOOM && !isNaN(Number(process.env.NEXT_PUBLIC_MAP_DEFAULT_ZOOM))
        ? Number(process.env.NEXT_PUBLIC_MAP_DEFAULT_ZOOM)
        : 7
  };
};

export default env;
