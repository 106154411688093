import flatten from 'flat';
import en from './en.json';

import '@formatjs/intl-pluralrules/polyfill-locales';

import env from '../src/env';

export type LocaleEnum = 'en';

export type Translation = {
  [key in LocaleEnum]: { [key: string]: string };
};

// A map that converts our locale code to the respective dayjs locale code
export const dayjsLocaleMap = {
  en: 'en-gb'
  // fr: 'fr-ch'
};

const locales: Translation = {
  en: flatten(en)
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const unflattennedLocales: { [key in LocaleEnum]: Record<string, string | any> } = {
  en
  // fr
};

export const defaultLocale = (env().NEXT_PUBLIC_DEFAULT_LOCALE || 'en') as LocaleEnum;

export default locales;
